<template>
  <div id='building' style="margin-left: -25px" >

  <el-card class="school-intro" style="border-radius: 20px;margin-top: 1px;color: #dddddd;font-family: 仿宋;text-align: left" >


    <el-image  class="school-seek" :src="seek" style="width: 60%; width:350px;height:auto; margin-top:0px;margin-left:-120px;border-radius: 20px;">
    </el-image>
    <meta>1、学校地址：西昌市马道街道办事处家园社区3组10号<br>

      2、公交路线：<br>

      （1）月城广场--25路公交车--“场口站”--下行1000米<br>

      （2）大成--101路公交车--“场口站”--下行1000米<br>

      3、报名电话：18009000080（陈校长）<br>

      &nbsp&nbsp;     18081638018（贺老师）<br>
  </el-card>
<!--    <el-card class="school-intro" style="border-radius: 20px;width:350px;height:30px;margin-top: 5px;color: #dddddd;font-family: 仿宋;text-align: left" >-->
<!--    <el-image  class="school-seek" :src="seek2" style="width: 350px; height:auto; margin-top:-45px;margin-left:-40px;border-radius: 20px;">-->
<!--    </el-image>-->
<!--    </el-card>-->
    <el-card class="school-intro" style="border-radius: 20px;width:350px;margin-top: 20px;color: #dddddd;font-family: 仿宋;text-align: left" >
      <el-image  class="school-seek" :src="seek2" style="width: 350px; height:auto; margin-top:-45px;margin-left:-40px;border-radius: 20px;">
      </el-image>
    <el-image  class="school-seek" :src="seek3" style="width: 350px; height:auto; margin-top:0px;margin-left:-30px;border-radius: 20px;">
    </el-image>

      <el-image  class="school-seek" :src="seek4" style="width: 200px;height:200px; margin-top:0px;;border-radius: 20px;margin-left:30px">
      </el-image>

    </el-card>
  </div>
</template>

<script>

import ElementPlus from "element-plus";


export default {
  name: "seek",
  data() {
    return {
      schoolName: 'Xichang Yuelin Middle School',
      seek: require('@/assets/s1.png'),
      seek2: require('@/assets/s2.png'),
      seek3: require('@/assets/s3.png'),
      seek4: require('@/assets/s4.jpg'),
    };
  },
}
</script>

<style scoped>
/* 样式可以根据你们学校的风格进行调整 */
.school-intro {
  font-family: Arial, sans-serif;
  max-width: 350px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

#building{
  background:url("~@/assets/背景059.jpg");
  width:350px;
  height:800px;
  border-radius: 20px;
  background-size: cover;

}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

ul {
  list-style: none;
  margin-top: 20px;
  padding-left: 0;
}

.school-intro{
border: 1px solid transparent;
border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}

li {
  font-size: 14px;
  margin-bottom: 5px;
}
</style>